<template>
  <section>
    <form class="form" role="form">
      <h4 v-once>
        {{ value.name || $t("new_process_area") }}
        <Tooltip v-if="!value.name" :title="$t('hints.process_area')" />
      </h4>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>{{ $t("name") }}</label>
          <input
            type="text"
            class="form-control"
            data-testid="name"
            v-model="formData.name"
            v-bind:placeHolder="$t('process_area_name')"
            ref="area_name"
            v-bind:disabled="!isReady"
            v-bind:readonly="!$can('manage', 'AreaDeProcessoEscrita')"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>{{ $t("description") }}</label>
          <input
            type="text"
            class="form-control"
            data-testid="description"
            v-model="formData.description"
            v-bind:placeHolder="$t('process_area_description')"
            v-bind:disabled="!isReady"
            v-bind:readonly="!$can('manage', 'AreaDeProcessoEscrita')"
          />
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { isEqual } from "lodash";
import Tooltip from "@/components/tooltip.vue";

export default {
  name: "ProcessAreaForm",
  components: { Tooltip },
  data() {
    return {
      action: "",
      hasError: false,
      formData: {
        name: "",
        description: ""
      }
    };
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    isReady() {
      return this.$store.getters["processArea/isReady"];
    },
    isValid() {
      return this.formData.name && this.formData.description;
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.formData)) return;
          this.$set(this, "formData", JSON.parse(JSON.stringify(n)));
        }
      },
      immediate: true,
      deep: true
    },
    formData: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.value)) return;
          this.$emit("input", this.formData);
        }
      },
      deep: true
    }
  },
  methods: {
    remove() {
      let self = this;
      if (self.processArea && "id" in self.processArea) {
        self
          .$swal({
            title: self.$t("are_you_sure"),
            text:
              self.$t("this_operation_might_be_affecting_other_equipment") +
              "\n" +
              self.$t("you_wont_be_able_to_revert_this"),
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
          })
          .then(function(isConfirm) {
            if (isConfirm) {
              self.action = "remove";
            }
          });
      }
    },
    save() {
      let self = this;
      let entry = {
        ...this.formData,
        contract_id: this.$store.getters["user/loggedUser"].contract_id
      };
      if (self.processArea && "id" in self.processArea) {
        entry.id = self.processArea.id;
      }
      self.action = "save";
      self.$store.dispatch("processArea/save", entry);
    }
  },
  mounted() {
    this.$refs.area_name.focus();
  }
};
</script>

<style scoped></style>
